import React from 'react'
import {
  // Home as HomeIcon,
  Menu as MenuIcon,
  Orders as OrdersIcon,
  Account as AccountIcon,
  ManageAccount as ManageAccountIcon,
} from 'icons'
import { Routes, RouteDefinitions } from 'compositions/Routes'
import { Login } from './Login'
import { ResetPassword, ForgotPassword } from './ResetPassword'
// import { Home } from './Home'
import { Menus } from './Menus'
import { Orders } from './Orders'
import {
  Account,
  MyFamily,
  Settings as AccountSettings,
  Subscriptions,
  CreateSubscription,
  EditSubscription,
} from './Account'
import { Profile } from './Profile'
import { TasteProfiles, TasteProfile } from './TasteProfiles'
import { useMeQuery } from 'hooks/useMe'
import { Box, Text } from 'components'

export const routes: RouteDefinitions = [
  // { path: '/', element: <Home />, name: 'Home', icon: HomeIcon, navMenu: true },
  { path: '/', element: <Menus />, name: 'Menus', icon: MenuIcon, navMenu: true },
  // { path: '/menus', element: <Menus />, name: 'Menus', icon: MenuIcon, navMenu: false },
  { path: '/orders', element: <Orders />, name: 'Orders', icon: OrdersIcon, navMenu: true },
  { path: '/account', element: <Account />, name: 'Account', icon: AccountIcon, navMenu: false, pathMatch: 'account' },
  { path: '/account/family', element: <MyFamily />, name: 'My Family', navMenu: false, parent: 'Account' },
  { path: '/account/settings', element: <AccountSettings />, name: 'Settings', navMenu: false, parent: 'Account' },

  { path: '/account/subscriptions', element: <Subscriptions />, name: 'Subscriptions', navMenu: false, parent: 'Account' },
  { path: '/account/subscriptions/create/:accountUserId', element: <CreateSubscription />, name: 'Setup Subscription', navMenu: false, parent: 'Subscriptions' },
  { path: '/account/subscriptions/create/:accountUserId/*', element: <CreateSubscription />, name: 'Setup Subscription', navMenu: false, parent: 'Subscriptions' },
  { path: '/account/subscriptions/edit/:accountUserId', element: <EditSubscription />, name: 'Edit Subscription', navMenu: false, parent: 'Subscriptions' },

  { path: '/account/tastes', element: <TasteProfiles />, name: 'Taste Profiles', navMenu: false, parent: 'Account' },
  { path: '/account/tastes/:accountUserId', element: <TasteProfile />, name: 'Taste Profile', navMenu: false, parent: 'Taste Profiles' },

  { path: 'profile', element: <Profile />, name: 'Manage Account', icon: ManageAccountIcon, navMenu: false },
  { path: 'login', element: <Login />, name: 'Login', public: true },
  { path: 'users/password/new', element: <ForgotPassword />, name: 'Forgot Password', public: true, navMenu: false },
  { path: 'users/password/edit', element: <ResetPassword />, name: 'Reset Password', public: true, navMenu: false },
  { path: '*', element: <Login />, name: 'Login', public: true },
]

export const Router: React.FC = () => {
  const { hasMarketAccess } = useMeQuery()

  if (hasMarketAccess) {
    return <Routes appName="Order Meals" routes={routes} />
  } else {
    return <Box>
      <Text>This app is unavailable.</Text>
    </Box>
  }
}

export default Router
