import { gql } from '@market/graphql/schema/gql'

export const accountOrdersQuery = gql(`
  query accountOrdersQuery($accountId: ID!) {
    accountOrders(accountId: $accountId) {
      rows {
        id
        orderProducts {
          id
          product {
            id
            name
          }
        }
      }
      rowCount
      pageInfo {
        hasNextPage
        truncated
      }
      aggregations {
        field
        buckets {
          label
          value
          count
          total
        }
      }
    }
  }
`)

export default accountOrdersQuery
