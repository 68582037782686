import React, { PropsWithChildren, createContext, useCallback, useReducer } from 'react'
import { useLocalStorage } from 'hooks'

export type MarketContext = object

const initialState: MarketContext = {}

export const ApplicationContext = createContext<MarketContext>(initialState)

export type ContextAction = undefined

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const contextReducer = (state: MarketContext, action: ContextAction) => {
  return state
}

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [stored, setStored] = useLocalStorage<MarketContext>('toolsContext', initialState)

  const localStorageReducer = useCallback(
    (state: MarketContext, action: ContextAction) => {
      const newState = contextReducer(state, action)
      setStored(newState)
      return newState
    },
    [setStored]
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useReducer(localStorageReducer, stored)

  const contextValue: MarketContext = {
    ...state,
  }

  return <ApplicationContext.Provider value={contextValue}>
    {children}
  </ApplicationContext.Provider>
}

export default ContextProvider