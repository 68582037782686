import React from 'react'
import { FilterProps } from '@market/hooks/useFilteredFamily'
import { FiltersDrawer } from './FiltersDrawer'
import { FiltersHeader } from './FiltersHeader'

export const FamilyFilters: React.FC<FilterProps> = ({
  account,
  availableSchoolLocations,
  appliedFilters,
  filters,
  filterDefinitions,
  applicable,
  clearable,
  setFilters,
  applyFilters,
  setAndApplyFilters,
  clearFilters,
}) => {
  return <>
    <FiltersDrawer
      filters={filters}
      filterDefinitions={filterDefinitions}
      applicable={applicable}
      clearable={clearable}
      setFilters={setFilters}
      applyFilters={applyFilters}
      setAndApplyFilters={setAndApplyFilters}
      clearFilters={clearFilters}
    />

    <FiltersHeader
      account={account}
      filters={appliedFilters}
      appliedFilters={appliedFilters}
      availableSchoolLocations={availableSchoolLocations}
      setAndApplyFilters={setAndApplyFilters}
    />
  </>
}

export default FamilyFilters
