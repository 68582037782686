import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { accountMenusQuery } from '@market/graphql/queries'
import type { Menu, Scalars } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountMenusQueryArgs = {
  accountId: string
  startDate?: Scalars['ISO8601DateTime']['input']
  endDate?: Scalars['ISO8601DateTime']['input']
}

type AccountMenusArgs = AccountMenusQueryArgs & {
  pollInterval?: number
}

export const useAccountMenus = ({ accountId, startDate, endDate, pollInterval }: AccountMenusArgs) => {
  const { data, ...rest } = useMarketQuery<{ accountMenus: Menu[] }, AccountMenusQueryArgs>(
    accountMenusQuery,
    {
      variables: { accountId, startDate, endDate },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      accountMenus: [],
      ...data,
    }
  }
}

export default useAccountMenus
