import React from 'react'
import { MobileStepper as MuiStepper, styled } from '@mui/material'
import { Button, ExtendedButtonProps } from 'components/Button'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { Back, Next } from 'icons'

const SubscriptionStepper = styled(MuiStepper)(({ theme, position="top" }) => ({
  ...(position === "top" && {
    top: theme.spacing(8),
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: '#ccc',
  }),
}))

export type MobileStepperProps = { wizard: UseSubscriptionWizard, position?: "top" | "bottom" | "static" }

type StepButtonProps = ExtendedButtonProps & Pick<MobileStepperProps, 'wizard'>

const NextButton: React.FC<StepButtonProps> = ({ wizard }) => {
  return <Button variant="text" color="plain" fullWidth={false} disabled={!wizard.nextStep || !wizard.nextStep.available(wizard.accountUser)} onClick={wizard.navigateToNext}>
    <Next fontSize="large" />
  </Button>
}

const BackButton: React.FC<StepButtonProps> = ({ wizard }) => {
  return <Button variant="text" color="plain" fullWidth={false} disabled={!wizard.prevStep} onClick={wizard.navigateToPrev}>
    <Back fontSize="large" />
  </Button>
}

export const MobileStepper: React.FC<MobileStepperProps> = ({ wizard, position="top" }) => {
  return <SubscriptionStepper
      variant="progress"
      position={position}
      steps={wizard.steps.length}
      activeStep={wizard.stepNumber}
      nextButton={<NextButton wizard={wizard} />}
      backButton={<BackButton wizard={wizard} />}
    />
}

export default MobileStepper
