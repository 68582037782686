import React from 'react'
import { Box } from 'components'
import { useFilteredFamily } from '@market/hooks'
import { Family, FamilyFilters } from '@market/compositions/Family'

export const MyFamily: React.FC = () => {
  const { account, filters, data: { filteredFamily } } = useFilteredFamily()

  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <FamilyFilters
      account={account}
      {...filters}
    />

    <Family family={filteredFamily} />
  </Box>
}

export default MyFamily
