import { useCallback, useReducer } from 'react'

export type StripeAddPaymentModalState = {
  open: boolean
  loading: boolean
  clientSecret?: string
  errors?: string[]
}

export type StripeAddPaymentModalAction =
  | { type: 'OPEN_MODAL' }
  | { type: 'CLOSE_MODAL' }
  | { type: 'CLEAR_MODAL' }
  | { type: 'SET_LOADING', payload: boolean }
  | { type: 'SET_ERRORS', payload: string[] }
  | { type: 'CLEAR_ERRORS' }
  | { type: 'SET_CLIENT_SECRET', payload: string }
  | { type: 'CLEAR_CLIENT_SECRET' }

const initialState: StripeAddPaymentModalState = {
  open: false,
  loading: false,
}

const stripeModalReducer = (state: StripeAddPaymentModalState, action: StripeAddPaymentModalAction) => {
  switch(action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
      }

    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
        loading: undefined,
        errors: undefined,
      }

    case 'CLEAR_MODAL':
      return {
        ...state,
        open: false,
        loading: undefined,
        errors: undefined,
      }

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      }

    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: undefined,
      }

    case 'SET_CLIENT_SECRET':
      return {
        ...state,
        clientSecret: action.payload,
      }

    case 'CLEAR_CLIENT_SECRET':
      return {
        ...state,
        clientSecret: undefined,
      }

    default:
      return state
  }
}

export const useStripeAddPaymentModal = (clientSecret?: string) => {
  const [ state, dispatch ] = useReducer(stripeModalReducer, { ...initialState, clientSecret })

  const openModal = useCallback(() => dispatch({ type: 'OPEN_MODAL' }), [dispatch])
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch])
  const clearModal = useCallback(() => dispatch({ type: 'CLEAR_MODAL' }), [dispatch])
  const setLoading = useCallback((loading: boolean) => dispatch({ type: 'SET_LOADING', payload: loading }), [dispatch])
  const setErrors = useCallback((errors: string[]) => dispatch({ type: 'SET_ERRORS', payload: errors }), [dispatch])
  const clearErrors = useCallback(() => dispatch({ type: 'CLEAR_ERRORS' }), [dispatch])
  const setClientSecret = useCallback((secret: string) => dispatch({ type: 'SET_CLIENT_SECRET', payload: secret }), [dispatch])
  const clearClientSecret = useCallback(() => dispatch({ type: 'CLEAR_CLIENT_SECRET' }), [dispatch])

  return {
    ...state,
    openModal,
    closeModal,
    clearModal,
    setLoading,
    setErrors,
    clearErrors,
    setClientSecret,
    clearClientSecret,
  }
}

export type UseStripeAddPaymentModalReturn = ReturnType<typeof useStripeAddPaymentModal>

export default useStripeAddPaymentModal
