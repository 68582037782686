import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled, StepIconProps } from '@mui/material'
import { Link } from 'components'
import type { StepIcons } from '@market/hooks/useSubscriptionWizard'
import { WizardStep } from '@market/hooks/useSubscriptionWizard'
import {
  Check as CheckIcon,
  Choices as ChoicesIcon,
  Payment as PaymentIcon,
  Settings as SettingsIcon,
} from 'icons'
import { AccountUser } from '@market/graphql/schema/graphql'

export const stepIcons: StepIcons = {
  payment: PaymentIcon,
  options: SettingsIcon,
  profile: ChoicesIcon,
  activate: CheckIcon,
}

const StepIconRoot = styled('div')(() => ({
  borderStyle: 'solid',
  borderWidth: [1],
  borderColor: 'inherit',
  color: 'inherit',
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export type WizardStepIconProps = StepIconProps & { step: WizardStep, accountUser: AccountUser }

export const StepIcon: React.FC<WizardStepIconProps> = (props) => {
  const { /*active, completed,*/ className, step, accountUser } = props
  const TodoIcon = stepIcons[step.step]

  if (step.available(accountUser)) {
    return <Link color="inherit" to={`/account/subscriptions/create/${accountUser.id}/${step.step}`} component={RouterLink}>
      <StepIconRoot className={className}>
        <TodoIcon className="StepIcon-circle" />
        {/* { active ? (
          <TodoIcon className="StepIcon-circle" />
        ) : (
          completed ? (
            <CheckIcon className="StepIcon-completedIcon" />
          ) : <TodoIcon className="StepIcon-circle" />
        )} */}
      </StepIconRoot>
    </Link>
  } else {
    return <StepIconRoot className={className}>
      <TodoIcon className="StepIcon-circle" />
      {/* { active ? (
        <TodoIcon className="StepIcon-circle" />
      ) : (
        completed ? (
          <CheckIcon className="StepIcon-completedIcon" />
        ) : <TodoIcon className="StepIcon-circle" />
      )} */}
    </StepIconRoot>
  }
}

export default StepIcon