import { useMe } from 'hooks'
import { useMemo } from 'react'
import useAccount from './useAccount'
import useAccountPaymentMethods from './useAccountPaymentMethods'
import useAccountCarts from './useAccountCarts'
import useAccountSubscriptions from './useAccountSubscriptions'

export const useCurrentAccount = () => {
  const { me } = useMe()
  const { data: accountData } = useAccount({ accountId: me?.account?.id })
  const { data: paymentMethodData } = useAccountPaymentMethods({ accountId: me?.account?.id })
  const { data: cartsData } = useAccountCarts({ accountId: me?.account?.id })
  const { data: subscriptionsData } = useAccountSubscriptions({ accountId: me?.account?.id })

  const account = useMemo(() => {
    return accountData?.account
  }, [accountData])

  const locations = useMemo(
    () =>
      account?.accountUsers?.map((acctUser) => acctUser.user.roster?.term?.location || acctUser.location )
      .filter((v, i, a) => v !== undefined && v !== null && a.indexOf(v) !== i)
      .sort((a, b) => a.displayName.localeCompare(b.displayName)) || [],
    [account?.accountUsers]
  )

  const subscriptionUsers = useMemo(() => {
    return account?.accountUsers?.filter((acctUser) => {
      return ((acctUser.user.roster?.term?.location || acctUser.location).settings?.opt_in_programs as string[])?.includes('SUBSCRIPTIONS')
    })
  }, [account])

  const subscriptionsEnabled = useMemo(() => {
    return subscriptionUsers?.length > 0
  }, [subscriptionUsers])

  const posEnabled = useMemo(() => {
    return locations.some((location) => !!location.settings?.pos_enabled)
  }, [locations])

  return {
    account,
    locations,
    paymentMethods: paymentMethodData?.accountPaymentMethods?.paymentMethods?.filter((paymentMethod) => !paymentMethod.isArchived),
    setupIntent: paymentMethodData?.accountPaymentMethods?.setupIntent,
    carts: cartsData?.accountCarts,
    subscriptions: subscriptionsData?.accountSubscriptions,
    subscriptionUsers: subscriptionUsers,
    posEnabled,
    subscriptionsEnabled,
  }
}

export default useCurrentAccount