import React from 'react'
import { PaymentMethodSelector, PaymentMethodSelectorProps } from './PaymentMethodSelector'
import FormField, { FieldProps } from 'components/Form/FormField'
import { getIn } from 'formik'

type FormPaymentMethodSelectorProps = Partial<FieldProps> & PaymentMethodSelectorProps

export const FormPaymentMethodSelector: React.FC<FormPaymentMethodSelectorProps> = ({ name, children, helperText, ...props }) => {
  return (
    <FormField
      name={name}
      component={({ form, field }) => {
        const { touched, errors } = form

        return (
          <PaymentMethodSelector
            {...props}
            error={Boolean(getIn(touched, name) && getIn(errors, name))}
            helperText={(getIn(touched, name) && getIn(errors, name)) || helperText}
            {...field}
          >
            {children}
          </PaymentMethodSelector>
        )
      }}
    />
  )
}

export default FormPaymentMethodSelector
