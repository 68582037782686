import React from 'react'
import { Box, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { CreateSubscriptionForm } from '../CreateSubscriptionForm'

export const PaymentMethodStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {

  return <Box>
    <Text variant="h4">{ wizard.activeStep.name }</Text>
    <CreateSubscriptionForm
      accountUser={wizard.accountUser}
      onSuccess={wizard.navigateToNext}
    />
  </Box>
}

export default PaymentMethodStep
