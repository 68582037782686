import { gql } from '@market/graphql/schema/gql'

export const activateSubscriptionMutation = gql(`
  mutation activateSubscriptionMutation($input: ActivateSubscriptionInput! ) {
    activateSubscription(input: $input) {
      subscription {
        ...SubscriptionFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default activateSubscriptionMutation
