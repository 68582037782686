import { gql } from '@market/graphql/schema/gql'

export const updateSubscriptionMutation = gql(`
  mutation updateSubscriptionMutation($input: UpdateSubscriptionInput! ) {
    updateSubscription(input: $input) {
      subscription {
        ...SubscriptionFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default updateSubscriptionMutation
