import { useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import type { Account, Menu, Scalars, Tag } from '@market/graphql/schema/graphql'
import { useAccountMenus } from './useAccountMenus'

export interface FilterState {
  startDate: Scalars['ISO8601Date']['input']
  endDate: Scalars['ISO8601Date']['input']
  timeZone: string
  productTagIds: string[]
  hideDates: ('weekends' | 'empty')[]
  visibleMenuProducts: boolean
}

export type FilterProps = Pick<BaseFilterProps<FilterState, Menu>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useAccountMenus>, "loading" | "loadedAt"> & {
  availableProductTags: Tag[]
}

const timeZoneFilter: Filter<FilterState, Menu, 'timeZone'> = {
  name: 'timeZone',
  label: 'Time Zone',
}

const startDateFilter: Filter<FilterState, Menu, 'startDate'> = {
  name: 'startDate',
  label: 'Start Date',
}

const endDateFilter: Filter<FilterState, Menu, 'endDate'> = {
  name: 'endDate',
  label: 'End Date',
}

const visibleMenuProductsFilter: Filter<FilterState, Menu, 'visibleMenuProducts'> = {
  name: 'visibleMenuProducts',
  label: 'Visible',
  process: (menu, { visibleMenuProducts }) => {
    const processedMenu = { ...menu, menuProducts: [] }

    menu.menuProducts.forEach((menuProduct) => {
      if (!visibleMenuProducts || menuProduct.visible) {
        processedMenu.menuProducts.push(menuProduct)
      }
    })

    return processedMenu
  },
}

const productTagFilter: Filter<FilterState, Menu, 'productTagIds'> = {
  name: 'productTagIds',
  label: 'Tags',
  process: (menu, { productTagIds }) => {
    const stateTagIds = productTagIds
    const processedMenu = { ...menu, menuProducts: [] }

    menu.menuProducts.forEach((menuProduct) => {
      const menuProductTagIds = menuProduct.product.tags.map((tag) => tag.id)
      let match = true

      stateTagIds.forEach((tagId) => {
        if (menuProductTagIds.indexOf(tagId) === -1) match = false
      })

      if (match) {
        processedMenu.menuProducts.push(menuProduct)
      }
    })

    return processedMenu
  },
}

const hideDatesFilter: Filter<FilterState, Menu, 'hideDates'> = {
  name: 'hideDates',
  label: 'Hide Dates',
  options: [
    { key: 'Hide Weekends', value: 'weekends'},
    { key: 'Hide Empty Dates', value: 'empty'},
  ]
}

export const useFilteredAccountMenus = (account: Account, initialState?: Partial<FilterState>) => {
  const timeZone = useMemo(() => account?.accountUsers[0]?.user?.roster?.term?.location?.timeZone || account?.accountUsers[0]?.location?.timeZone, [account])

  const filters = useFilters<FilterState, Menu>(
    [
      timeZoneFilter,
      startDateFilter,
      endDateFilter,
      productTagFilter,
      hideDatesFilter,
      visibleMenuProductsFilter,
    ],
    {
      timeZone: timeZone,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      startDate: DateTime.fromISO(initialState?.startDate || DateTime.now().toISO()).setZone(timeZone).startOf('week', { useLocaleWeeks: true }).plus({ weeks: 1 }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      endDate: DateTime.fromISO(initialState?.endDate || initialState?.startDate || DateTime.now().toISO()).setZone(timeZone).endOf('week', { useLocaleWeeks: true }).plus({ weeks: 1 }),
      productTagIds: [...(initialState?.productTagIds || [])],
      hideDates: [...(initialState?.hideDates || [])],
      visibleMenuProducts: true,
    },
    [
      'startDate',
      'endDate'
    ]
  )

  const { filterData, appliedFilters, setFilterOptions } = filters

  const { data, ...rest } = useAccountMenus({
    accountId: account?.id,
    startDate: appliedFilters.startDate.toISO(),
    endDate: appliedFilters.endDate.toISO(),
    pollInterval: 6000000,
  })

  const availableProductTags = useMemo(
    () =>
      data?.accountMenus.flatMap((menu) => menu.menuProducts.flatMap((menuProduct) => menuProduct.product.tags))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.accountMenus]
  )

  useEffect(() => {
    setFilterOptions('productTagIds', availableProductTags.map((tag) => ({ key: tag.name, value: tag.id })))
  }, [ setFilterOptions, availableProductTags ])

  const filteredMenus = useMemo(() => filterData(data.accountMenus), [filterData, data.accountMenus])

  return {
    data: {
      ...data,
      filteredMenus,
    },
    filters: {
      ...filters,
      availableProductTags,
    },
    ...rest
  }
}

export default useFilteredAccountMenus
