import React from 'react'
import { Alert, Box, Button, Form, FormSubmitButton } from 'components'
import { OnSuccessFn } from 'hooks/useMutationForm'
import { AccountUser } from '@market/graphql/schema/graphql'
import { useCurrentAccount, useStripeAddPaymentModal } from '@market/hooks'
import { useCreateSubscriptionForm, CreateSubscriptionFormInput } from '@market/hooks/useCreateSubscriptionForm'
import { CreateSubscriptionPayload } from '@market/graphql/schema/graphql'
import { FormPaymentMethodSelector } from '@market/components/PaymentMethodSelector'
import { AddPaymentMethodModal } from '../StripePayments'

export const CreateSubscriptionForm: React.FC<{ accountUser: AccountUser, onSuccess?: OnSuccessFn<CreateSubscriptionPayload, CreateSubscriptionFormInput> }> = ({ accountUser, onSuccess }) => {
  const { paymentMethods, setupIntent } = useCurrentAccount()
  const [ mutationForm, { errors, loading, submitted } ] = useCreateSubscriptionForm({ accountUser, paymentMethods: paymentMethods }, { onSuccess })
  const modal = useStripeAddPaymentModal(setupIntent?.clientSecret)
  const { openModal } = modal

  return <>
    <AddPaymentMethodModal {...modal} />
    <Form form={mutationForm}>
      <Box>
        <FormPaymentMethodSelector
          id="paymentMethodId"
          name="paymentMethodId"
          paymentMethods={paymentMethods}
        />

        <Button onClick={openModal} fullWidth={false}>Add payment method</Button>
      </Box>

      { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton loading={loading}>
        Save
      </FormSubmitButton>
    </Form>
  </>
}

export default CreateSubscriptionForm
