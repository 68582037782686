import React from 'react'
import { FilterProps } from '@market/hooks/useFilteredAvailableProducts'
import { FiltersDrawer } from './FiltersDrawer'
import { FiltersHeader } from './FiltersHeader'

export const ProductFilters: React.FC<FilterProps> = ({
  loading,
  availableProductTags,
  appliedFilters,
  filters,
  filterDefinitions,
  applicable,
  clearable,
  setFilters,
  applyFilters,
  setAndApplyFilters,
  clearFilters,
}) => {
  return <>
    <FiltersDrawer
      filters={filters}
      filterDefinitions={filterDefinitions}
      applicable={applicable}
      clearable={clearable}
      setFilters={setFilters}
      applyFilters={applyFilters}
      setAndApplyFilters={setAndApplyFilters}
      clearFilters={clearFilters}
    />

    <FiltersHeader
      loading={loading}
      filters={appliedFilters}
      availableProductTags={availableProductTags}
    />
  </>
}

export default ProductFilters
