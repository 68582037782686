import { useEffect, useMemo } from 'react'
import type { AccountUser, SchoolLocation } from '@market/graphql/schema/graphql'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import { useFamily } from './useFamily'

export interface FilterState {
  schoolLocationIds: string[]
  userName: string
}

export type FilterProps = Pick<BaseFilterProps<FilterState, AccountUser>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Partial<ReturnType<typeof useFamily>> & {
  availableSchoolLocations: SchoolLocation[]
}

const schoolLocationIdsFilter: Filter<FilterState, AccountUser, 'schoolLocationIds'> = {
  name: 'schoolLocationIds',
  label: 'Schools',
  match: (accountUser, { schoolLocationIds }) => {
    return schoolLocationIds.length === 0 || schoolLocationIds.indexOf(accountUser.location.id) > -1
  },
}

const userNameFilter: Filter<FilterState, AccountUser, 'userName'> = {
  name: 'userName',
  label: 'Student Name',
  match: (accountUser, { userName }) => {
    if (!userName || userName === '') return true

    return (userName && userName !== '') && (
      !!(accountUser.user.firstName || "").match(new RegExp(`${userName}`, 'i')) ||
      !!(accountUser.user.preferredName || "").match(new RegExp(`${userName}`, 'i')) ||
      !!(accountUser.user.middleName || "").match(new RegExp(`${userName}`, 'i')) ||
      !!(accountUser.user.lastName || "").match(new RegExp(`${userName}`, 'i'))
    )
  },
}

export const useFilteredFamily = (initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, AccountUser>(
    [
      schoolLocationIdsFilter,
      userNameFilter
    ],
    {
      schoolLocationIds: [...(initialState?.schoolLocationIds || [])],
      userName: '',
    }
  )

  const { filterData, setFilterOptions } = filters

  const { account: data, locations, ...rest } = useFamily()

  useEffect(() => {
    setFilterOptions('schoolLocationIds', locations.map((location) => ({ key: location.displayName, value: location.id })))
  }, [ setFilterOptions, locations ])

  const filteredFamily = useMemo(() => filterData(data.accountUsers), [filterData, data.accountUsers])

  return {
    account: data,
    data: {
      family: data.accountUsers,
      filteredFamily,
    },
    filters: {
      ...filters,
      availableSchoolLocations: locations,
    },
    ...rest
  }
}

export default useFilteredFamily
