import React, { useCallback } from 'react'
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import stripePromise from 'utils/stripe'
import { useViewport } from 'hooks/useViewport'
import type { UseStripeAddPaymentModalReturn } from '@market/hooks/useStripeAddPaymentModal'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Text,
} from 'components'
import { Close as CloseIcon } from 'icons'

const AddPaymentMethodForm: React.FC<UseStripeAddPaymentModalReturn> = ({ loading, errors, setLoading, setErrors, clearErrors }) => {
  const stripe = useStripe()
  const elements = useElements()

  const submitForm = useCallback(async (event: React.FormEvent) => {
    event.preventDefault()

    if (!stripe || !elements) return

    clearErrors()

    const formStatus = (await elements.submit())
    if (!!formStatus.error) {
      return
    }

    setLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    })

    if (!!error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setErrors([error.message])
      } else {
        setErrors(["An unexpected error occurred."])
      }
    }

    setLoading(false)
  }, [stripe, elements, setLoading, setErrors, clearErrors])

  return <form id="payment-method-form" onSubmit={submitForm}>
    <DialogContent>
      <Box display="flex" flexDirection="column" width="100%">
        <PaymentElement />
      </Box>
    </DialogContent>

    <DialogActions>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gap={2}>
        <Box>
          <Text color="error" fontWeight="medium">{ errors && errors.join(', ') }</Text>
        </Box>

        <Box>
          <Button
            sx={(theme) => ({ minWidth: theme.spacing(20) }) }
            loading={loading}
            type="submit"
          >
            Done
          </Button>
        </Box>
      </Box>
    </DialogActions>
  </form>
}

export const AddPaymentMethodModal: React.FC<UseStripeAddPaymentModalReturn> = (props) => {
  const { open, clientSecret, closeModal } = props
  const { isLarge } = useViewport()

  return <Dialog fullScreen={!isLarge} fullWidth={isLarge} open={open} onClose={closeModal} maxWidth="md">
    <DialogTitle sx={{ textAlign: 'center' }}>
      <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>

    <Elements options={{ clientSecret, currency: 'usd' }} stripe={stripePromise}>
      <AddPaymentMethodForm {...props} />
    </Elements>
  </Dialog>
}

export default AddPaymentMethodModal
