import React from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Chip,
  Stack
} from 'components'
import { User } from '@market/graphql/schema/graphql'

export interface AccountUserCardProps {
  user: User
}

export const AccountUserCard: React.FC<AccountUserCardProps> = ({ user }) => {
  return <Card sx={{ display: 'flex', flexDirection: 'column' }}>
    <CardHeader
      title={user.displayName}
    />

    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textWrap: 'balance', }}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          {user.roster && <Chip size="medium" label={`${user.roster.groupName} ${user.roster.name}`} /> }
        </Box>
      </Box>
    </CardContent>

    <CardActions>
      <Box flexGrow={1} display="flex" alignItems="flex-end">
        <Stack direction="column" flexWrap="wrap" width="100%">
          <Box></Box>
        </Stack>
      </Box>
    </CardActions>
  </Card>
}

export default AccountUserCard
