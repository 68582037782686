import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from 'components'
import { useCurrentAccount } from '@market/hooks'
import { SubscriptionWizardRouter } from '@market/compositions/Subscriptions'

export const CreateSubscription: React.FC = () => {
  const { accountUserId } = useParams()
  const { account } = useCurrentAccount()
  const accountUser = useMemo(() => account.accountUsers?.find((acctUser) => acctUser.id === accountUserId), [account, accountUserId])

  return <Box height="100%">
    <SubscriptionWizardRouter accountUser={accountUser} />
  </Box>
}

export default CreateSubscription
