import React, { useCallback, useMemo } from 'react'
import { Box, Button, Card, CardActions, CardContent, CardMedia, Text } from 'components'
import { AccountUser, Product, ProductReactionEnum } from '@market/graphql/schema/graphql'
import { useAddProductReaction, useRemoveProductReaction } from '@market/hooks'

type AddProductReactionFn = (productId: Product['id'], reaction: ProductReactionEnum) => void
type RemoveProductReactionFn = (productId: Product['id']) => void

export const ProductCard: React.FC<{
  accountUser: AccountUser,
  product: Product,
  addProductReaction: AddProductReactionFn,
  removeProductReaction: RemoveProductReactionFn
}> = ({ accountUser, product, addProductReaction, removeProductReaction }) => {
  const reaction = useMemo(() => accountUser.user.reactions?.find((reaction) => reaction.productId === product.catalogProductId), [accountUser, product])
  const liked = useMemo(() => reaction?.reaction === 'like', [reaction])
  const disliked = useMemo(() => reaction?.reaction === 'dislike', [reaction])

  const onClickLike = useCallback(() => {
    if (liked) {
      removeProductReaction(product.catalogProductId)
    } else {
      addProductReaction(product.catalogProductId, ProductReactionEnum.Like)
    }
  }, [liked, product, addProductReaction, removeProductReaction])

  const onClickDislike = useCallback(() => {
    if (disliked) {
      removeProductReaction(product.catalogProductId)
    } else {
      addProductReaction(product.catalogProductId, ProductReactionEnum.Dislike)
    }
  }, [disliked, product, addProductReaction, removeProductReaction])

  return <Card sx={{ display: 'flex', flexDirection: 'column' }}>
    <CardMedia
      image={product.featuredImage}
      sx={{ height: '140px' }}
    />

    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textWrap: 'balance', }}>
      <Box pb={2}>
        <Text variant="h6">{ product.name }</Text>
      </Box>
      <Box>
        <Text>{ product.description }</Text>
      </Box>
    </CardContent>

    <CardActions sx={(theme) => ({ borderTopWidth: '1px', borderTopColor: theme.palette.grey[300], borderTopStyle: 'solid' })}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Button
            fullWidth={false}
            color={disliked ? 'primary' : 'plain'}
            onClick={onClickDislike}
          >NO</Button>
        </Box>
        <Box>
          <Button
            fullWidth={false}
            color={liked ? 'primary' : 'plain'}
            onClick={onClickLike}
          >YES</Button>
        </Box>
      </Box>
    </CardActions>
  </Card>
}

export const ProductGrid: React.FC<{ accountUser: AccountUser, products: Product[] }> = ({ accountUser, products }) => {
  const [addProductReactionMutation] = useAddProductReaction()
  const [removeProductReactionMutation] = useRemoveProductReaction()

  const addProductReaction = useCallback((productId: Product['id'], reaction: ProductReactionEnum) => {
    addProductReactionMutation({ variables: { productId, reaction, userId: accountUser.user.id } })
  }, [accountUser, addProductReactionMutation])

  const removeProductReaction = useCallback((productId: Product['id']) => {
    removeProductReactionMutation({ variables: { productId, userId: accountUser.user.id } })
  }, [accountUser, removeProductReactionMutation])

  return <Box display="flex" flexWrap="wrap" gap={2}>
    { products.map((product) => <ProductCard
      key={product.id}
      accountUser={accountUser}
      product={product}
      addProductReaction={addProductReaction}
      removeProductReaction={removeProductReaction}
    />) }
  </Box>
}

export default ProductGrid
