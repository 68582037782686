import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useMutationForm, MutationFormTuple, CoerceFn, MutationFormConfig, OnSuccessFn } from 'hooks/useMutationForm'
import { CreateSubscriptionPayload, CreateSubscriptionInput, AccountUser, StripePaymentMethod  } from '@market/graphql/schema/graphql'
import { useCreateSubscription } from './useCreateSubscription'

export const zodSchema = z.object({
  accountUserId: z.string(),
  paymentMethodId: z.string(),
})

export type CreateSubscriptionFormInput = Required<z.infer<typeof zodSchema>>

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useCreateSubscriptionForm = ({ accountUser, paymentMethods }: { accountUser: AccountUser, paymentMethods: StripePaymentMethod[] }, config?: MutationFormConfig<CreateSubscriptionPayload, CreateSubscriptionFormInput>): MutationFormTuple<CreateSubscriptionPayload, CreateSubscriptionInput> => {
  const coerce: CoerceFn<CreateSubscriptionInput, CreateSubscriptionFormInput> = (values) => {
    const coerced: CreateSubscriptionInput = {
      accountUserId: values.accountUserId,
      paymentMethodId: values.paymentMethodId,
    }

    return coerced  
  }

  const onSuccess: OnSuccessFn<CreateSubscriptionPayload, CreateSubscriptionFormInput> = (data, helpers) => {
    enqueueSnackbar("Subscription saved", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
    if (config?.onSuccess) config.onSuccess(data, helpers)
  }

  const [ mutationForm, mutationResult] = useMutationForm<CreateSubscriptionPayload, CreateSubscriptionInput, CreateSubscriptionFormInput>(useCreateSubscription, {
    ...config,
    coerce,
    onSuccess,
    validationSchema,
    initialValues: {
      accountUserId: accountUser.id,
      paymentMethodId: accountUser.subscription?.paymentMethodId || paymentMethods.find((pm) => pm.default)?.stripeId || paymentMethods[0]?.stripeId,
    }
  })

  return [ mutationForm, mutationResult ]
}

export default useCreateSubscriptionForm