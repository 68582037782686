import { gql } from '@market/graphql/schema/gql'

export const addProductReactionMutation = gql(`
  mutation addProductReactionMutation($input: AddProductReactionInput! ) {
    addProductReaction(input: $input) {
      reaction {
        id
        productId
        userId
        reaction
        user {
          id
          reactions {
            id
            productId
            userId
            reaction
          }
        }
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default addProductReactionMutation
