import React, { useMemo } from 'react'
import { Menu, MenuProduct, Section } from '@market/graphql/schema/graphql'
import { Box, Text } from 'components'
import { DateTime } from 'luxon'
import { BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { chunk } from 'lodash'
import { FilterState } from '@market/hooks/useFilteredAccountMenus'

export interface CalendarDate {
  date: DateTime
  menuProducts: MenuProduct[]
}

export interface MenuCalendar {
  menu: Menu
  dates: CalendarDate[]
}

const DateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '200px',
  borderWidth: 1,
  borderColor: theme.palette.divider,
  borderStyle: 'solid',
}))

const WeeklyCalendarDate: React.FC<CalendarDate & BoxProps> = ({ date, menuProducts, ...props }) => {
  const sections: { section: Section, menuProducts: MenuProduct[] }[] = useMemo(() => {
    const menuSections: { section: Section, menuProducts: MenuProduct[] }[] = []

    menuProducts.forEach((menuProduct) => {
      const sectionIndex = menuSections.findIndex((menuSection) => menuSection.section.id === menuProduct.section.id)
      if (sectionIndex >= 0) {
        menuSections[sectionIndex].menuProducts.push(menuProduct)
      } else {
        menuSections.push({ section: menuProduct.section, menuProducts: [menuProduct]})
      }
    })

    return menuSections
  }, [ menuProducts ])

  return <DateBox {...props}>
    <Box flexGrow={1} width="100%" sx={(theme) => ({ backgroundColor: menuProducts.length > 0 ? 'none' : theme.palette.grey['50'], opacity: menuProducts.length > 0 ? 1 : 0.5 })}>
      <Box display="flex" justifyContent="space-between" px={1} sx={(theme) => ({ borderWidth: 1, borderColor: theme.palette.divider, borderStyle: 'solid' })}>
        <Box><Text fontWeight="fontWeightLight" textTransform="uppercase">{ date.toFormat('ccc') }</Text></Box>
        <Box><Text fontWeight="fontWeightRegular">{ date.toFormat('LLL d') }</Text></Box>
      </Box>

      <Box>
        { sections.sort((a,b) => a.section.name.localeCompare(b.section.name)).map((section) => <Box key={section.section.id} display="flex" flexDirection="column" my={2} pt={2} sx={(theme) => ({ borderTopWidth: 1, borderTopColor: theme.palette.divider, borderTopStyle: 'solid', '&:first-child': { borderTopWidth: 0, paddingTop: 0 } })}>
          <Box display="flex" alignItems="center" px={1} title={section.section.name}>
            <Text fontWeight="fontWeightMedium" >{ section.section.name }</Text>
          </Box>

          { section.menuProducts.sort((a, b) => a.product.name.localeCompare(b.product.name)).map((menuProduct) =>
            <Box key={menuProduct.id} display="flex" alignItems="center" px={1} pt={1} title={menuProduct.product.name}>
              <img src={(menuProduct.product.images[0]?.uri || menuProduct.product.featuredImage)} height="50px" width="50px" alt={menuProduct.product.name} />
              <Text fontWeight="fontWeightRegular"  pl={1}>{ menuProduct.product.name }</Text>
            </Box>
          )}
        </Box>) }
      </Box>
    </Box>
  </DateBox>
}

export const WeeklyCalendar: React.FC<{ calendar: MenuCalendar, appliedFilters: FilterState }> = ({ calendar: { dates, menu }, appliedFilters }) => {
  const weeks: CalendarDate[][] = useMemo(() => [ ...chunk<CalendarDate>(dates, 7) ], [dates])

  return <Box>
    <Box mb={2} sx={(theme) => ({ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: theme.palette.divider})} title={`${menu.name} at ${menu.location.name}`}>
      <Text variant="h5" fontWeight="medium">{`${menu.name} at ${menu.location.name}`}</Text>
    </Box>
    <Box display="flex" flexDirection="column" rowGap={2} pb={3} width="100%">
      { weeks.map((weekDates) => <Box key={weekDates[0].date.toISO()} display="flex" flexWrap="wrap" justifyContent="flex-start" rowGap={2} mb={4} width="100%">
        { weekDates.map((date) => {
          if (appliedFilters.hideDates.includes('empty') && date.menuProducts.length === 0) return null
          if (appliedFilters.hideDates.includes('weekends') && (date.date.toFormat('ccc') === 'Sun' || date.date.toFormat('ccc') === 'Sat')) return null
          return <WeeklyCalendarDate key={date.date.toISO()} {...date} sx={{ flexGrow: 1, width: { xl: '14.28%', lg: '25%', xs: '100%' } }} />
        })}
      </Box>) }
    </Box>
  </Box>
}

export default WeeklyCalendar
