import { useEffect, useMemo } from 'react'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import type { AccountUser, Product, Tag } from '@market/graphql/schema/graphql'
import { useAvailableProducts } from './useAvailableProducts'

export interface FilterState {
  productTagIds: string[]
}

export type FilterProps = Pick<BaseFilterProps<FilterState, Product>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useAvailableProducts>, "loading"> & {
  availableProductTags: Tag[]
}

const productTagFilter: Filter<FilterState, Product, 'productTagIds'> = {
  name: 'productTagIds',
  label: 'Tags',
  match: (product, { productTagIds }) => {
    if (productTagIds.length === 0) return true
    return productTagIds.some((tagId) => product.tags.some((tag) => tag.id === tagId))
  },
}

export const useFilteredAvailableProducts = (accountUser: AccountUser, initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, Product>(
    [
      productTagFilter,
    ],
    {
      productTagIds: [...(initialState?.productTagIds || [])],
    },
    [],
  )

  const { filterData, setFilterOptions } = filters

  const { data, ...rest } = useAvailableProducts({
    locationId: accountUser?.user?.roster?.term?.location?.id || accountUser?.location?.id,
    pollInterval: 6000000,
  })

  const availableProductTags = useMemo(
    () =>
      data?.availableProducts.flatMap((product) => product.tags)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.availableProducts]
  )

  useEffect(() => {
    setFilterOptions('productTagIds', availableProductTags.map((tag) => ({ key: tag.name, value: tag.id })))
  }, [ setFilterOptions, availableProductTags ])

  const filteredProducts = useMemo(() => filterData(data.availableProducts), [filterData, data.availableProducts])

  return {
    data: {
      ...data,
      filteredProducts,
    },
    filters: {
      ...filters,
      availableProductTags,
    },
    ...rest
  }
}

export default useFilteredAvailableProducts
