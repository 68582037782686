import type { AccountSubscriptionsQueryQuery, AccountSubscriptionsQueryQueryVariables } from '@market/graphql/schema/graphql'
import { accountSubscriptionsQuery } from '@market/graphql/queries'
import { useMarketQuery } from './useMarketQuery'

type AccountSubscriptionsArgs = AccountSubscriptionsQueryQueryVariables & {
  pollInterval?: number
}

export const useAccountSubscriptions = ({ accountId, pollInterval }: AccountSubscriptionsArgs) => {
  const { data, ...rest } = useMarketQuery<AccountSubscriptionsQueryQuery, AccountSubscriptionsQueryQueryVariables>(
    accountSubscriptionsQuery,
    {
      variables: { accountId },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  return {
    ...rest,
    data: {
      accountSubscriptions: [],
      ...data,
    }
  }
}

export default useAccountSubscriptions
