import React from 'react'
import { Box, Text } from 'components'
import { useCurrentAccount } from '@market/hooks'
import { SubscriptionsList } from '@market/compositions/Subscriptions'

export const Subscriptions: React.FC = () => {
  const { account, subscriptionUsers } = useCurrentAccount()

  return <Box>
    <Text variant="h4">Manage Subscriptions</Text>

    <SubscriptionsList account={account} users={subscriptionUsers} />
  </Box>
}

export default Subscriptions
