import React from 'react'
// import Markdown from 'react-markdown'
// import rehypeRaw from 'rehype-raw'
import { Link as RouterLink } from 'react-router-dom'
import { Stepper as MuiStepper, Step, StepLabel, StepLabelProps, StepContent, styled, StepIconProps } from '@mui/material'
import { Box, Link, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { StepIcon } from './StepIcon'

const StyledStepLabel = styled(StepLabel)<{
  ownerState: { completed?: boolean; active?: boolean, available?: boolean };
} & StepLabelProps>(({ theme, ownerState }) => ({
  color: theme.palette.divider,
  fontWeight: 'normal',
  ...(ownerState.available && {
    color: 'inherit',
  }),
  ...(ownerState.completed && {
    color: 'inherit',
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }),
}))


const StepLabelText = styled(Text)<{
  ownerState: { completed?: boolean; active?: boolean, available?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.divider,
  fontWeight: 'normal',
  ...(ownerState.available && {
    color: 'inherit',
  }),
  ...(ownerState.completed && {
    color: 'inherit',
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }),
}))

const SubscriptionStepper = styled(MuiStepper)((/*{ theme }*/) => ({
  ".MuiStepLabel-root": {
    flexDirection: "row-reverse",
    ".MuiStepLabel-labelContainer": {
      textAlign: 'right',
    },
    ".MuiStepLabel-iconContainer": {
      paddingLeft: 8,
    },
  },
  ".MuiStepContent-root": {
    marginLeft: 0,
    marginRight: 16,
    paddingRight: 16,
    paddingLeft: 8,
    borderLeft: "none",
    borderRight: "1px solid rgb(189, 189, 189)"
  },
  ".MuiStepConnector-vertical": {
    marginLeft: 0,
    marginRight: 16,
    ".MuiStepConnector-lineVertical": {
      borderLeftStyle: "none",
      borderLeftWidth: 0,
      borderRightStyle: "solid",
      borderRightWidth: 1,
    },
  },
}))

export const Stepper: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  return <SubscriptionStepper activeStep={wizard.stepNumber} orientation="vertical" nonLinear sx={{ width: 400 }}>
    { wizard.steps?.map((step) => {
      return <Step key={step.step} completed={step.completed(wizard.accountUser)}>
        {/* TODO: figure out a better way to not have to cast props */}
        <StyledStepLabel ownerState={{ completed: step.completed(wizard.accountUser), active: step.step === wizard.activeStep.step, available: step.available(wizard.accountUser) }} StepIconComponent={StepIcon} StepIconProps={{ step, accountUser: wizard.accountUser } as unknown as StepIconProps}>
          { step.available(wizard.accountUser) && <Link color="inherit" to={`/account/subscriptions/create/${wizard.accountUser.id}/${step.step}`} replace={true} component={RouterLink}>
            <StepLabelText ownerState={{ completed: step.completed(wizard.accountUser), active: step.step === wizard.activeStep.step, available: step.available(wizard.accountUser) }} variant="h6">{step.name}</StepLabelText>
          </Link> }
          { !step.available(wizard.accountUser) && <StepLabelText ownerState={{ completed: step.completed(wizard.accountUser), active: step.step === wizard.activeStep.step, available: step.available(wizard.accountUser) }} variant="h6">{step.name}</StepLabelText> }
        </StyledStepLabel>
        <StepContent>
          <Box mb={2} textAlign="right">
            STEP SUMMARY GOES HERE IF WE WANT
          </Box>
        </StepContent>
      </Step>
    }) }
  </SubscriptionStepper>
}

export default Stepper
