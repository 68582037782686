import React, { useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import {
  Box,
  Chip,
  Text,
} from 'components'
import { FilterState, FilterProps } from '@market/hooks/useFilteredAccountMenus'
import { DateTime } from 'luxon'

export type FiltersHeaderProps = {
  filters: FilterState
} & Pick<FilterProps, "loading" | "loadedAt" | "availableProductTags">

export const FiltersHeader: React.FC<FiltersHeaderProps> = ({ loading, loadedAt, availableProductTags, filters }) => {
  const [tick, setTick] = useState(DateTime.now())

  useEffect(() => {
    const tickInterval = setInterval(() => setTick(DateTime.now()), 5000)
    return () => clearInterval(tickInterval)
  })

  return <>
    <Box display="flex" alignItems="center">
      <Box>
        <Box>
          <Text variant="h4">Menus</Text>
        </Box>
      </Box>

      <Box m={1} flexGrow={1} sx={{ textAlign: "right" }}>
        <Box sx={{ width: '100%' }} display={{ xs: 'none', md: 'block' }}>
          { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>}
        </Box>
      </Box>
    </Box>

    <Box mb={2}>
      <Box sx={{ width: '100%' }} display={{ xs: 'block', md: 'none' }}>
        { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>}
      </Box>
      { filters.startDate.toFormat("LLL d") === filters.endDate.toFormat("LLL d") && <Chip size="small" label={filters.startDate.toFormat("LLL d")} sx={{ mr: 1 }} /> }
      { filters.startDate.toFormat("LLL d") !== filters.endDate.toFormat("LLL d") && <Chip size="small" label={`${filters.startDate.toFormat("LLL d")} - ${filters.endDate.toFormat("LLL d")}`} sx={{ mr: 1 }} /> }

      { filters.productTagIds.map((id) => {
        const tag = availableProductTags.find((tag) => tag.id === id)
        if (!tag) return null

        return <Chip size="small" key={tag.id} label={tag.name} sx={{ mr: 1 }} />
      })}
    </Box>
  </>
}

export default FiltersHeader
