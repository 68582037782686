import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Text } from 'components'
import { useCurrentAccount } from '@market/hooks'
import { EditSubscriptionForm } from '@market/compositions/Subscriptions'

export const EditSubscription: React.FC = () => {
  const { accountUserId } = useParams()
  const { account: { accountUsers } } = useCurrentAccount()
  const accountUser = useMemo(() => accountUsers?.find((acctUser) => acctUser.id === accountUserId), [accountUsers, accountUserId])

  if (!accountUser || !accountUser.subscription) return null // TODO: redirect to /subscriptions/create

  return <Box>
    <Text variant="h4">Edit Subscription</Text>

    <EditSubscriptionForm accountUser={accountUser} />
  </Box>
}

export default EditSubscription
