import React from 'react'
import { Box } from 'components'
import { useAccountOrders, useCurrentAccount } from '@market/hooks'

export const Orders: React.FC = () => {
  const { account } = useCurrentAccount()
  const { data: { accountOrders } } = useAccountOrders({ accountId: account.id })

  return <Box><pre>{ JSON.stringify(accountOrders, null, 2) }</pre></Box>
}

export default Orders
