import type { ActivateSubscriptionInput, ActivateSubscriptionPayload } from '@market/graphql/schema/graphql'
import { activateSubscriptionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type ActivateSubscriptionMutationData = { activateSubscription: ActivateSubscriptionPayload }

export const useActivateSubscription: (variables?: ActivateSubscriptionInput) => MutationTuple<ActivateSubscriptionMutationData, ActivateSubscriptionInput> = (variables)  => {
  const [ activateSubscriptionFn, { data, ...result } ] = useMarketMutation<ActivateSubscriptionMutationData, ActivateSubscriptionInput>(
    activateSubscriptionMutation,
    {
      variables,
      notifyOnNetworkStatusChange: true
    }
  )

  return [ activateSubscriptionFn, { ...result, data } ]
}

export default useActivateSubscription
