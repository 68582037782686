import React, { useMemo } from 'react'
import DefaultCardLogo from 'theme/cc/card-default.svg'
import VisaLogo from 'theme/cc/visa.svg'
import MastercardLogo from 'theme/cc/mastercard.svg'
import AmExLogo from 'theme/cc/amex.svg'
import DiscoverLogo from 'theme/cc/discover.svg'

export type PaymentMethodIconType = 'visa' | 'mastercard' | 'amex' | 'discover'

export type PaymentMethodIconMap = {
  [key in PaymentMethodIconType]: React.FC<React.SVGAttributes<SVGElement>>
}

export const PaymentMethodIconMap = {
  visa: VisaLogo,
  mastercard: MastercardLogo,
  amex: AmExLogo,
  discover: DiscoverLogo,
}

export const PaymentMethodIcon: React.FC<React.SVGAttributes<SVGElement> & { type?: PaymentMethodIconType }> = ({ type, ...svgProps }) => {
  const PaymentMethodLogo = useMemo(() => type ? PaymentMethodIconMap[type] : DefaultCardLogo, [type])
  return <PaymentMethodLogo {...svgProps} />
}

export default PaymentMethodIcon
