import React from 'react'
import { Box, Button, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { TasteProfile } from '@market/compositions/TasteProfile'

export const TasteProfileStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {

  return <Box height="100%" display="flex" flexDirection="column">
    <Box>
      <Text variant="h4">{ wizard.activeStep.name }</Text>
    </Box>
    <Box height="100%" overflow="auto" flexGrow={1} pb={4}>
      <TasteProfile accountUser={wizard.accountUser} />
    </Box>
    <Box p={2}>
      <Button disabled={!wizard.activeStep.completed(wizard.accountUser)} onClick={() => wizard.navigateToNext()}>Done!</Button>
    </Box>
  </Box>
}

export default TasteProfileStep
