import React from 'react'
import { Box } from 'components'
import { AccountUser } from '@market/graphql/schema/graphql'
import { useFilteredAvailableProducts } from '@market/hooks'
import { ProductGrid } from './ProductGrid'
import { ProductFilters } from './ProductFilters'

export const TasteProfile: React.FC<{ accountUser: AccountUser }> = ({ accountUser }) => {
  const {
    loading, // TODO: Show loading state
    filters,
    data: { filteredProducts }
  } = useFilteredAvailableProducts(accountUser)

  return <Box>
    <ProductFilters
      loading={loading}
      {...filters}
    />

    <ProductGrid accountUser={accountUser} products={filteredProducts} />
  </Box>
}

export default TasteProfile
