import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useMutationForm, MutationFormTuple, CoerceFn, MutationFormConfig, OnSuccessFn } from 'hooks/useMutationForm'
import { UpdateSubscriptionPayload, UpdateSubscriptionInput, AccountUser, SubscriptionDailyOptionsInput } from '@market/graphql/schema/graphql'
import { useUpdateSubscription } from './useUpdateSubscription'

export const dailyOptionSchema = z.object({
  active: z.boolean(),
  enabled: z.boolean(),
}).required()

export const productSlotSchema = z.object({
  enabled: z.boolean(),
  productType: z.string(),
  quantity: z.number(),
})

export const menuSlotSchema = z.object({
  enabled: z.boolean(),
  mealService: z.string(),
  productSlots: z.array(productSlotSchema),
})

export const zodSchema = z.object({
  subscription: z.object({
    id: z.string(),
    status: z.string().optional(),
    paymentMethodId: z.string(),
    dailyOptions: z.object({
      monday: dailyOptionSchema,
      tuesday: dailyOptionSchema,
      wednesday: dailyOptionSchema,
      thursday: dailyOptionSchema,
      friday: dailyOptionSchema,
      saturday: dailyOptionSchema,
      sunday: dailyOptionSchema,
    }).required().refine((data) => {
      return Object.values(data).filter((day) => day.active && day.enabled).length >= 2
    }, { message: "Please select at least 2"}),
    subscriptionOptions: z.object({
      menuSlots: z.array(menuSlotSchema),
    }),
    subscriptionTagIds: z.array(z.string()),
  }).required(),
})

export type UpdateSubscriptionFormInput = UpdateSubscriptionInput //Required<z.infer<typeof zodSchema> & { id: string }>

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useEditSubscriptionForm = (accountUser: AccountUser, config?: MutationFormConfig<UpdateSubscriptionPayload, UpdateSubscriptionFormInput>): MutationFormTuple<UpdateSubscriptionPayload, UpdateSubscriptionInput> => {
  const coerce: CoerceFn<UpdateSubscriptionInput, UpdateSubscriptionFormInput> = (values) => {
    const coerced: UpdateSubscriptionInput = {
      subscription: {
        ...values.subscription,
        dailyOptions: Object.keys(values.subscription.dailyOptions).reduce((options, day) => {
          const dailyOption = values.subscription.dailyOptions[day]
          return {
            ...options,
            [day]: {
              enabled: dailyOption.enabled,
            }
          }
        }, values.subscription.dailyOptions),
      }
    }

    return coerced  
  }

  const onSuccess: OnSuccessFn<UpdateSubscriptionPayload, UpdateSubscriptionFormInput> = (data, helpers) => {
    enqueueSnackbar("Subscription saved", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
    if (config?.onSuccess) config.onSuccess(data, helpers)
  }

  const [ mutationForm, mutationResult] = useMutationForm<UpdateSubscriptionPayload, UpdateSubscriptionInput, UpdateSubscriptionFormInput>(useUpdateSubscription, {
    ...config,
    coerce,
    onSuccess,
    validationSchema,
    initialValues: {
      subscription: {
        id: accountUser.subscription.id,
        status: accountUser.subscription.status,
        paymentMethodId: accountUser.subscription.paymentMethodId,
        dailyOptions: Object.keys(accountUser.subscription.dailyOptions).reduce((options, day) => {
          const dailyOption = accountUser.subscription.dailyOptions[day]
          if (day === '__typename') return options

          return {
            ...options,
            [day]: {
              active: dailyOption.active,
              enabled: dailyOption.enabled,
            }
          }
        }, {}) as SubscriptionDailyOptionsInput,
        subscriptionOptions: {
          menuSlots: accountUser.subscription.subscriptionOptions.menuSlots.map((menuSlot) => ({
            enabled: menuSlot.enabled,
            mealService: menuSlot.mealService,
            productSlots: menuSlot.productSlots.map((productSlot) => ({
              enabled: productSlot.enabled,
              productType: productSlot.productType,
              quantity: productSlot.quantity,
            })),
          })),
        },
        subscriptionTagIds: accountUser.subscription.subscriptionTags?.map((subtag) => subtag.tag.id),
      },
    }
  })

  return [ mutationForm, mutationResult ]
}

export default useEditSubscriptionForm