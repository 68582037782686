import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { accountCartsQuery } from '@market/graphql/queries'
import type { Cart } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountCartsQueryArgs = {
  accountId: string
}

type AccountCartsArgs = AccountCartsQueryArgs & {
  pollInterval?: number
}

export const useAccountCarts = ({ accountId, pollInterval }: AccountCartsArgs) => {
  const { data, ...rest } = useMarketQuery<{ accountCarts: Cart[] }, AccountCartsQueryArgs>(
    accountCartsQuery,
    {
      variables: { accountId },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      accountCarts: [],
      ...data,
    }
  }
}

export default useAccountCarts
