import React from 'react'
import { AccountUser } from '@market/graphql/schema/graphql'
import { WizardStep } from './WizardStep'

export const SubscriptionWizard: React.FC<{ accountUser: AccountUser }> = ({ accountUser }) => {
  if (accountUser.subscription) {
    return <WizardStep accountUser={accountUser} step="options" />
  } else {
    return <WizardStep accountUser={accountUser} step="payment" />
  }
}

export default SubscriptionWizard
