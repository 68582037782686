import { gql } from '@market/graphql/schema/gql'

export const createSubscriptionMutation = gql(`
  mutation createSubscriptionMutation($input: CreateSubscriptionInput! ) {
    createSubscription(input: $input) {
      accountUser {
        id
        subscription {
          ...SubscriptionFields
        }
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default createSubscriptionMutation
