import type { Product } from '@market/graphql/schema/graphql'
import { availableProductsQuery } from '@market/graphql/queries'
import { useMarketQuery } from './useMarketQuery'

type AvailableProductsQueryArgs = {
  locationId: string
}

type AvailableProductsArgs = AvailableProductsQueryArgs & {
  pollInterval?: number
}

export const useAvailableProducts = ({ locationId, pollInterval }: AvailableProductsArgs) => {
  const { data, ...rest } = useMarketQuery<{ availableProducts: Product[] }, AvailableProductsQueryArgs>(
    availableProductsQuery,
    {
      variables: { locationId },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  return {
    ...rest,
    data: {
      availableProducts: [],
      ...data,
    }
  }
}

export default useAvailableProducts
