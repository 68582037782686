import React from 'react'
import {
  Box,
  Chip,
  Text,
} from 'components'
import { FilterState, FilterProps } from '@market/hooks/useFilteredFamily'
import { Account } from '@market/graphql/schema/graphql'

export type FiltersHeaderProps = {
  account: Account
  filters: FilterState
} & Pick<FilterProps, "filters" | "setAndApplyFilters" | "appliedFilters" | "availableSchoolLocations">

export const FiltersHeader: React.FC<FiltersHeaderProps> = ({ account, appliedFilters, availableSchoolLocations }) => {
  return <>
    <Box display="flex" alignItems="center">
      <Box>
        <Box>
          <Text variant="h4">{ account?.name }</Text>
        </Box>
      </Box>
    </Box>

    <Box mb={2}>
      { appliedFilters.schoolLocationIds.length === 0 && <Chip size="small" label="All Schools" sx={{ mr: 1 }} /> }
      { appliedFilters.schoolLocationIds.map((id) => {
        const location = availableSchoolLocations.find((location) => location.id === id)
        if (!location) return null

        return <Chip size="small" key={location.id} label={location.displayName} sx={{ mr: 1 }} />
      })}

      { appliedFilters.userName && appliedFilters.userName !== '' && <Chip size="small" label={`Name: "${appliedFilters.userName}"`} sx={{ mr: 1 }} /> }
    </Box>
  </>
}

export default FiltersHeader
