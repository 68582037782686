import { OperationVariables, QueryHookOptions, QueryResult, DocumentNode, TypedDocumentNode } from '@apollo/client'
import { useQuery } from 'hooks'
// import useCurrentSchoolLocation from './useCurrentSchoolLocation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMarketQuery = <TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables>): QueryResult<TData, TVariables> => {
  // const { schoolLocation } = useCurrentSchoolLocation()

  return useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'cache-and-network',
    context: {
      ...options.context,
      clientName: 'market',
      headers: {
        // 'ORDO-TENANT-ORGANIZATION-ID': schoolLocation?.organization?.id,
        // 'ORDO-TENANT-LOCATION-ID': schoolLocation?.id,
      }
    }
  })
}

export default useMarketQuery