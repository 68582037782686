import { gql } from '@market/graphql/schema/gql'

export const setDefaultPaymentMethodMutation = gql(`
  mutation setDefaultPaymentMethodMutation($input: SetDefaultPaymentMethodInput! ) {
    setDefaultPaymentMethod(input: $input) {
      paymentMethods {
        ...PaymentMethodFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default setDefaultPaymentMethodMutation
