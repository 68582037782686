import { gql } from '@market/graphql/schema/gql'

export const archivePaymentMethodMutation = gql(`
  mutation archivePaymentMethodMutation($input: ArchivePaymentMethodInput! ) {
    archivePaymentMethod(input: $input) {
      paymentMethods {
        ...PaymentMethodFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default archivePaymentMethodMutation
