import React from 'react'
import { useMe } from 'hooks'
import { Box, Button } from 'components'
import { useAccountPaymentMethods, useStripeAddPaymentModal } from '@market/hooks'
import { AddPaymentMethodModal, PaymentMethodList } from '@market/compositions/StripePayments'

export const Settings: React.FC = () => {
  const { me } = useMe()
  const { data: { accountPaymentMethods: { paymentMethods, setupIntent } } } = useAccountPaymentMethods({ accountId: me?.account?.id })
  const modal = useStripeAddPaymentModal(setupIntent?.clientSecret)
  const { openModal } = modal

  return <Box>
    <PaymentMethodList paymentMethods={paymentMethods} />
    <Button onClick={openModal} fullWidth={false}>Add payment method</Button>
    <AddPaymentMethodModal {...modal} />
  </Box>
}

export default Settings
