import React from 'react'
import { Box } from 'components'
import { AccountUserCard } from '@market/components'
import type { AccountUser } from '@market/graphql/schema/graphql'

export const FamilyGrid: React.FC<{ family: AccountUser[] }> = ({ family }) => {
  return <Box display="flex" gap={4} flexWrap="wrap">
    { family.map((acctUser) => <AccountUserCard key={acctUser.id} user={acctUser.user} />) }
  </Box>
}

export default FamilyGrid
