import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { AccountUser } from '@market/graphql/schema/graphql'
import { SubscriptionWizard } from './SubscriptionWizard'
import { WizardStep } from './WizardStep'
import { Step } from '@market/hooks/useSubscriptionWizard'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

export const WizardRoute: React.FC<{ accountUser: AccountUser }> = ({ accountUser }) => {
  const { step } = useParams()

  return <WizardStep step={step as Step} accountUser={accountUser} />
}

export const Router: React.FC<{ accountUser: AccountUser }> = ({ accountUser }) => {
  return <SentryRoutes>
    <Route path="/" element={<SubscriptionWizard accountUser={accountUser} />} />
    <Route path="/:step" element={<WizardRoute accountUser={accountUser} />} />
  </SentryRoutes>
}

export default Router
