import { gql } from '@market/graphql/schema/gql'

export const accountMenusQuery = gql(`
  query accountMenusQuery($accountId: ID!, $startDate: ISO8601Date, $endDate: ISO8601Date) {
    accountMenus(accountId: $accountId, startDate: $startDate, endDate: $endDate) {
      id
      name
      description
      fullAvailabilityDates
      location {
        id
        name
        displayName
      }
      menuProducts {
        id
        fullAvailabilityDates
        visible
        section {
          id
          name
        }
        product {
          ...SchoolProductFields
        }
        variant {
          id
          product {
            ...SchoolProductFields
          }
        }
      }
    }
  }
`)

export default accountMenusQuery
