import { gql } from '@market/graphql/schema/gql'

export const removeProductReactionMutation = gql(`
  mutation removeProductReactionMutation($input: RemoveProductReactionInput! ) {
    removeProductReaction(input: $input) {
      reaction {
        id
        productId
        userId
        reaction
        user {
          id
          reactions {
            id
            productId
            userId
            reaction
          }
        }
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default removeProductReactionMutation
