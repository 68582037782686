import type {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  TypedDocumentNode,
  MutationHookOptions,
  OperationVariables } from "@apollo/client"
import { useMutation, MutationTuple } from 'hooks/useMutation'
// import useCurrentSchoolLocation from './useCurrentSchoolLocation'

export type { MutationTuple }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMarketMutation = <TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(mutation: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: MutationHookOptions<TData, TVariables, TContext>): MutationTuple<TData, TVariables, TContext, TCache> => {
  // const { schoolLocation } = useCurrentSchoolLocation()

  return useMutation<TData, TVariables, TContext, TCache>(mutation, {
    ...options,
    context: {
      ...options.context,
      clientName: 'market',
      headers: {
        // 'ORDO-TENANT-ORGANIZATION-ID': schoolLocation?.organization?.id,
        // 'ORDO-TENANT-LOCATION-ID': schoolLocation?.id,
      }
    }
  })
}

export default useMarketMutation