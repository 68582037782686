import React, { useMemo } from 'react'
import { Menu } from '@market/graphql/schema/graphql'
import { FilterState } from '@market/hooks/useFilteredAccountMenus'
import { WeeklyCalendar, CalendarDate, MenuCalendar } from './WeeklyCalendar'
import { DateTime, Interval } from 'luxon'

export const Menus: React.FC<{ menus: Menu[], appliedFilters: FilterState }> = ({ menus, appliedFilters }) => {
  const calendars: MenuCalendar[] = useMemo(() => menus.sort((a,b) => a.location.name.localeCompare(b.location.name)).map((menu) => {
    const menuCalendar: MenuCalendar = { menu, dates: []}

    for (const day of Interval.fromDateTimes(appliedFilters.startDate, appliedFilters.endDate).splitBy({ days: 1 })) {
      const calendarDate: CalendarDate = { date: day.start, menuProducts: [] }

      menu.menuProducts.forEach((menuProduct) => {
        if (!menuProduct.fullAvailabilityDates.map((availabilityDate) => {
          return DateTime.fromFormat(availabilityDate, 'y-LL-dd').toFormat('yLLdd')
        }).includes(day.start.toFormat('yLLdd'))) return

        calendarDate.menuProducts.push(menuProduct)
      })

      menuCalendar.dates.push(calendarDate)
    }

    return menuCalendar
  }), [appliedFilters, menus])

  return <>{ calendars.map((calendar) => <WeeklyCalendar key={calendar.menu.id} calendar={calendar} appliedFilters={appliedFilters} />)}</>
}

export default Menus
