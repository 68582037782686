import { gql } from '@market/graphql/schema/gql'

export const accountCartsQuery = gql(`
  query accountCartsQuery($accountId: ID!) {
    accountCarts(accountId: $accountId) {
      id
      user {
        id
        firstName
        preferredName
        middleName
        lastName
      }
      cartItems {
        id
        product {
          id
          name
        }
      }
    }
  }
`)

export default accountCartsQuery
