import React from 'react'
import { Box } from 'components'
import { Account, AccountUser } from '@market/graphql/schema/graphql'
import { SubscriptionCard } from './SubscriptionCard'

export const SubscriptionsList: React.FC<{ account: Account, users: AccountUser[] }> = ({ account, users }) => {
  return <Box display="flex" flexDirection="column" gap={2} pt={2}>
    { users?.map((acctUser) => {
      return <SubscriptionCard key={acctUser.id} account={account} user={acctUser} />
    }) }
  </Box>
}

export default SubscriptionsList
