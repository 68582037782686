import { gql } from '@market/graphql/schema/gql'

export const accountPaymentMethodsQuery = gql(`
  query accountPaymentMethodsQuery($accountId: ID!) {
    accountPaymentMethods(accountId: $accountId) {
      setupIntent {
        id
        stripeId
        status
        clientSecret
      }
      paymentMethods {
        ...PaymentMethodFields
      }
    }
  }
`)

export default accountPaymentMethodsQuery
