import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { accountPaymentMethodsQuery } from '@market/graphql/queries'
import type { AccountPaymentMethods } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountPaymentMethodsQueryArgs = {
  accountId: string
}

type AccountPaymentMethodsArgs = AccountPaymentMethodsQueryArgs & {
  pollInterval?: number
}

export const useAccountPaymentMethods = ({ accountId, pollInterval }: AccountPaymentMethodsArgs) => {
  const { data, ...rest } = useMarketQuery<{ accountPaymentMethods: AccountPaymentMethods }, AccountPaymentMethodsQueryArgs>(
    accountPaymentMethodsQuery,
    {
      variables: { accountId },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      accountPaymentMethods: {
        setupIntent: {},
        paymentMethods: [],
        ...data?.accountPaymentMethods
      },
      ...data,
    }
  }
}

export default useAccountPaymentMethods
