import type { UpdateSubscriptionInput, UpdateSubscriptionPayload } from '@market/graphql/schema/graphql'
import { updateSubscriptionMutation } from '@market/graphql/mutations'
import { MutationTuple, useMarketMutation } from './useMarketMutation'

export type UpdateSubscriptionMutationData = { updateSubscription: UpdateSubscriptionPayload }

export const useUpdateSubscription: (variables?: UpdateSubscriptionInput) => MutationTuple<UpdateSubscriptionPayload, UpdateSubscriptionInput> = (variables)  => {
  const [ updateSubscriptionFn, { data, ...result } ] = useMarketMutation<UpdateSubscriptionPayload, UpdateSubscriptionInput>(
    updateSubscriptionMutation,
    {
      variables,
      notifyOnNetworkStatusChange: true
    }
  )

  return [ updateSubscriptionFn, { ...result, data } ]
}

export default useUpdateSubscription
