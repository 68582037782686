import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { accountOrdersQuery } from '@market/graphql/queries'
import type { AccountOrdersPayload } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountOrdersQueryArgs = {
  accountId: string
}

type AccountOrdersArgs = AccountOrdersQueryArgs & {
  pollInterval?: number
}

export const useAccountOrders = ({ accountId, pollInterval }: AccountOrdersArgs) => {
  const { data, ...rest } = useMarketQuery<{ accountOrders: AccountOrdersPayload }, AccountOrdersQueryArgs>(
    accountOrdersQuery,
    {
      variables: { accountId },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      accountOrders: {
        rows: []
      },
      ...data,
    }
  }
}

export default useAccountOrders
