import { gql } from '@market/graphql/schema/gql'

export const accountSubscriptionsQuery = gql(`
  query accountSubscriptionsQuery($accountId: ID!) {
    accountSubscriptions(accountId: $accountId) {
      ...SubscriptionFields
    }
  }
`)

export default accountSubscriptionsQuery
