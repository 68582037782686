import { gql } from '@market/graphql/schema/gql'

export const accountUserSubscriptionQuery = gql(`
  query accountUserSubscriptionQuery($accountUserId: ID!) {
    accountUserSubscription(accountUserId: $accountUserId) {
      ...SubscriptionFields
    }
  }
`)

export default accountUserSubscriptionQuery
