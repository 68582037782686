import React from 'react'
import {
  Box,
  Button,
  Text,
} from 'components'
import { ContentDrawerPortal } from 'compositions/ContentDrawer'
import { Cart } from '@market/graphql/schema/graphql'
import { Cart as CartIcon } from 'icons'

export type CartsProps = {
  carts: Cart[]
}

export const Carts: React.FC<CartsProps> = ({ carts }) => {
  return <ContentDrawerPortal overflow="hidden" icon={CartIcon} drawerType="carts">
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" overflow="auto" p={2}>
        <Box pb={4}>
          <Text variant="h5" fontWeight="medium">Cart</Text>
        </Box>

        <Box>
          <pre>{ JSON.stringify(carts, null, 2) }</pre>
        </Box>
      </Box>

      <Box p={2}>
        <Button variant="contained">Checkout</Button>
      </Box>
    </Box>
  </ContentDrawerPortal>
}

export default Carts
