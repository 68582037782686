import React from 'react'
import { Box, Text } from 'components'
import { UseSubscriptionWizard } from '@market/hooks/useSubscriptionWizard'
import { EditSubscriptionForm } from '@market/compositions/Subscriptions/EditSubscriptionForm'

export const EditSubscriptionStep: React.FC<{ wizard: UseSubscriptionWizard }> = ({ wizard }) => {
  return <Box>
    <Text variant="h4">{ wizard.activeStep.name }</Text>
    <EditSubscriptionForm
      accountUser={wizard.accountUser}
      onSuccess={wizard.navigateToNext}
    />
  </Box>
}

export default EditSubscriptionStep
