import { gql } from '@market/graphql/schema/gql'

export const accountQuery = gql(`
  query accountQuery($accountId: ID!) {
    account(accountId: $accountId) {
      id
      name
      accountUsers {
        id
        user {
          id
          firstName
          middleName
          lastName
          preferredName
          displayName
          features {
            key
            enabled
          }
          reactions {
            id
            productId
            userId
            reaction
          }
          roster {
            id
            name
            description
            group
            groupName
            sortOrder
            term {
              id
              name
              location {
                id
                name
                displayName
                timeZone
                settings
                organization {
                  id
                  name
                }
              }
            }
          }
        }
        location {
          id
          name
          displayName
          timeZone
          settings
          organization {
            id
            name
          }
        }
        subscription {
          ...SubscriptionFields
        }
      }
    }
  }
`)

export default accountQuery
