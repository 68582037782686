import { accountQuery } from '@market/graphql/queries'
import type { Account } from '@market/graphql/schema/graphql'
import { useMarketQuery } from './useMarketQuery'

type AccountQueryArgs = {
  accountId: string
}

type AccountArgs = AccountQueryArgs & {
  pollInterval?: number
}

export const useAccount = ({ accountId, pollInterval }: AccountArgs) => {
  const { data, ...rest } = useMarketQuery<{ account: Account }, AccountQueryArgs>(
    accountQuery,
    {
      variables: { accountId },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  return {
    ...rest,
    data: {
      account: {},
      ...data,
    }
  }
}

export default useAccount
