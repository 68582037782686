import { gql } from '@market/graphql/schema/gql'

export const availableProductsQuery = gql(`
  query availableProductsQuery($locationId: ID!) {
    availableProducts(locationId: $locationId) {
      ...SchoolProductFields
    }
  }
`)

export default availableProductsQuery
